import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import GridList from '@material-ui/core/GridList';
//import GridListTile from '@material-ui/core/GridListTile';
//import GridListTileBar from '@material-ui/core/GridListTileBar';
import axios from 'axios';
import { useAuth } from "../context/auth";
import { handleError } from "../components/ErrorHandler";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { API_URL } from '../constants/index.js';
import {
  Container,
} from 'semantic-ui-react'
import { CardWide, Logo, Form, Error } from "../components/AuthForm";
import { Button, Input } from 'reactbulma'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const Allies = () => {
  
  const [loadStyle, setLoadStyle] = useState("");
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const { setAuthTokens } = useAuth();
  const { authTokens } = useAuth();
  
  const postInvite = async () => {
    setLoadStyle("is-loading");
    alert(`${API_URL}/invite`);
    await axios.put(
      `${API_URL}/invite}`,
        {  email: email },
        {  headers: { Authorization: `${authTokens}`} },
    ).then(function (response) {
      alert('yes');
      //setOriginalNotesContent(notesContent);
      //iterateProjects("changeNotes", selectedTask, notesContent);
    }).catch(function (e) {
      alert(e);
       setIsError(true);
        //handleError(e);
    });
  }
  
  return (
    <Container fluid center aligned style={{ marginTop: '5em', width: '900px'  }}>
      <h1 className="ui header">Allies</h1>
      <div>Invite a friend! Enter your friend's email address to invite them to become an ally.</div>
      <CardWide>
        <Form onSubmit={postInvite}>
          <div>
            <label htmlFor="email">Email Address</label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              placeholder="you@monthalution.com"
            />
          </div>
          <div className='inputField'>
              <Button variant="contained" primary type="submit" value="Send Invitation" className={loadStyle} >Send Invitation</Button>
          </div>
        </Form>
        { isError &&<Error>The username or password provided were incorrect!</Error> }
      </CardWide>
    </Container>
  );
}

export default Allies;