import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import GridList from '@material-ui/core/GridList';
//import GridListTile from '@material-ui/core/GridListTile';
//import GridListTileBar from '@material-ui/core/GridListTileBar';
import axios from 'axios';
import { useAuth } from "../context/auth";
import { handleError } from "../components/ErrorHandler";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { API_URL } from '../constants/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));


const SavedLinks = () => {
  const classes = useStyles();
  //const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tileData, setTileData] = useState([]);
  const [numCols, setNumCols] = useState(5);

  const { authTokens } = useAuth();

  // Gets the task list from the API
  useEffect(() => {
    const fetchData = async () => {
      await axios.get(
          `${API_URL}/resources`,
		{  headers: { Authorization: `${authTokens}`} },
      ).then(function (result) {
          let td = [];
          for (let i=0; i<200; i++) {
            td.push({
              id: result.data[i].id,
              img: result.data[i].og_image || imgDefault,
              title: result.data[i].usage,
              url: result.data[i].url
            });
          }
          setTileData(td);
      }).catch(e => {
        handleError(e);
    });
    };
    fetchData();
  }, [authTokens]);

  useEffect(() => {
    if (!isLoaded) {
      if (window.innerWidth>1500) {
        setNumCols(5);
      } else if (window.innerWidth>1200) {
        setNumCols(4);
      } else if (window.innerWidth>900) {
        setNumCols(3);
      } else if (window.innerWidth>600) {
        setNumCols(2);
      } else  {
        setNumCols(1);
      }
      setIsLoaded(true);
    }
  }, [isLoaded]);
    
  const imgDefault = '/monthalution-link-image.png';
  
  const itemStyle = {
    cursor: 'pointer',
  };
  const tileStyle = {
    transition: 'width 400ms',
  };
  const trashStyle = {
    display: 'none',
      position: 'absolute',
      top: '10px',
      right: '10px'
  };
  
  // Initiates item deletion
  function deleteItem(id) {
    axios.delete(`${API_URL}/resources/${id}`,
    {  headers: { Authorization: `${authTokens}`} },
    ).then(result => {
      if (result.status === 200) {
        let element = document.getElementById("resource" + id);
        element.style.width = '0px';
        setTimeout(function(){ element.remove(); }, 400);
      } else {
        //setIsError(true);
      }
    }).catch(e => {
      handleError(e);
      //setIsError(true);
    });
    //alert(id);
  }
  
  return (
    <div>
      <div className="container">
      {tileData.map((tile) => (
        <div className="card" key={'resource' + tile.id} id={'resource' + tile.id} onClick={() => { window.open(tile.url, '_blank'); }} onMouseOver={() => { document.getElementById('resourceTrash'+tile.id).style.display = 'block'; }} onMouseOut={() => { document.getElementById('resourceTrash'+tile.id).style.display = 'none'; }}>
          <div className="card-image">
            <figure className="image is-16by9">
              <img src={tile.img} alt={tile.title} onError={(e)=>{e.target.onerror = null; e.target.src=imgDefault}} loading="lazy" />
            </figure>
              <button id={'resourceTrash' + tile.id} aria-label={`info about ${tile.title}`} style={trashStyle} className="button is-danger" onClick={(e) => { e.stopPropagation(); deleteItem(tile.id); }}>
                <span className="icon is-small">
                  <i className="fa-solid fa-trash-can"></i>
                </span>
              </button>
          
          </div>
          <div className="card-content bulma-card-content">
              {tile.title}
          </div>
        </div>
      ))}
      </div>
    </div>
  );
}

export default SavedLinks;