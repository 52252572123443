import React from 'react';
import MineIcon from '@material-ui/icons/Brightness5';
import FlagIcon from '@material-ui/icons/Flag';

const Square = (props) => {
  
  const cname = () => {
    return (props.clicked === 1) ? "square isClicked" : "square";
  }
  
  const cont = () => {
    if (props.gameStatus==='loser') {
      return (props.content === "*") ? <MineIcon fontSize="inherit" /> : props.content;
    } else {
      if (props.clicked === 1) {
        return (props.content === "*") ? <FlagIcon fontSize="inherit" /> : props.content;
      } else {
        return (props.clicked === 2) ? <FlagIcon fontSize="inherit"  /> : "";
      }
    }
  }
  
  return (
    <button
      className={cname()}
      onClick={props.onClick}
    >
      {cont()}
    </button>
  );
}

export default Square;