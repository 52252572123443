import React, { useState } from 'react';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';
import { Button, Input } from 'reactbulma'

const StartForm = (props) => {
  
  const [cols, setCols] = useState(15);
  const [rows, setRows] = useState(10);
  const [mines, setMines] = useState(30);
    
  function handleSubmit(event) {
    props.onClick(cols,rows,mines);
    event.preventDefault();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='inputField'>
        <label htmlFor="columns">Columns</label>
        <Input
          id="columns"
          value={cols}
          onChange={e => setCols(e.target.value)}
        />
      </div>
      <div className='inputField'>
        <label htmlFor="rows">Rows</label>
        <Input
          id="rows"
          value={rows}
          onChange={e => setRows(e.target.value)}
        />
      </div>
      <div className='inputField'>
        <label htmlFor="mines">Mines</label>
        <Input
          id="mines"
          value={mines}
          onChange={e => setMines(e.target.value)}
        />
      </div>
      <div className='inputField'>
        <Button variant="contained" primary type="submit" value="New Game" >New Game</Button>
      </div>
    </form>
  );

}

export default StartForm;