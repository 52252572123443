import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import logoImg from "../img/logo.svg";
import { Card, Logo, Form, Error } from "../components/AuthForm";
import { Button, Input } from 'reactbulma'
import { useAuth } from "../context/auth";
import { API_URL } from '../constants/index.js';

const Login = (props) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadStyle, setLoadStyle] = useState("");
  const { setAuthTokens } = useAuth();
  const referer = '/';

  function postLogin(e) {
    setLoadStyle("is-loading");
    e.preventDefault();
    
    axios.post(`${API_URL}/signin`, {
      email,
      password
    }).then(result => {
      setLoadStyle("");
      if (result.data.isError) {
          setIsError(true);
      } else {
          setAuthTokens(result.data);
          setLoggedIn(true);
      }
    }).catch(e => {
      setIsError(true);
    });
  }

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  } else {
  	return (
		<Card>
	      <Logo src={logoImg} />
	      <Form onSubmit={postLogin}>
            <div className='inputField'>
              <label htmlFor="username">Email</label>
              <Input
                id="username"
                type="username"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                placeholder="you@monthalution.com"
              />
            </div>
            <div className='inputField'>
              <label htmlFor="password">Password</label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                placeholder="******"
              />
            </div>
            <div className='inputField'>
                <Button variant="contained" primary type="submit" value="Sign In" className={loadStyle} >Sign In</Button>
            </div>
	      </Form>
	        { isError &&<Error>The username or password provided were incorrect!</Error> }
	    </Card>
  	  );
  }
}

export default Login;