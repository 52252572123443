import React, { useEffect, useState, useRef } from 'react';
import StartForm from './components/StartForm.js';
import Board from './components/Board.js';
import { Card } from 'reactbulma'
import {
  Container,
} from 'semantic-ui-react'

const Minesweeper = (props) => {
  
  //const [mines, setMines] = useState(0);
  const [gameStatus, setGameStatus] = useState('new');
  const [mineFlags, setMineFlags] = useState(0);
  const [squares, setSquares] = useState([]);
  const [clicked, setClicked] = useState([]);
  //const [started, setStarted] = useState(false);
  
  const didMountRef = useRef(false);
  
  useEffect(() => {
    if (didMountRef.current) {
      let flags = 0;
      if (squares.length) {
        let winner = true;
        // Iterate through all squares
        for (let i = 0; i < squares.length; i++) {
          for (let j = 0; j < squares[i].length; j++) {
            // Count mine flags
            if (clicked[i][j]===2) {
              flags++;
            }
            // Check not-clicked squares
            if (clicked[i][j]===1 && squares[i][j] === '*') {
              setGameStatus("loser");
              return;
            } else if (clicked[i][j]!==1 && squares[i][j] !== '*') {
              winner = false;
            }
          }
        }
        if (winner) {
          if (gameStatus !== "winner") {
            setGameStatus("winner");
          }
          return;
        } else {
          if (gameStatus !== "playing") {
            setGameStatus("playing");
          }
          if (mineFlags !== flags) {
            setMineFlags(flags);
          }
          return;
        }
      }
      if (gameStatus !== "new") {
        setGameStatus("new");
      }
    } else {
      startNewGame(15,10,30);
      didMountRef.current = true;
    }
  }, [squares, gameStatus, clicked, mineFlags]);
  
  function handleClick(i,j) {
    let gu = gameStatus;
    if (gu === "playing") {
      const tempClicked = clicked.slice();
      if (window.event.shiftKey) {
        if (tempClicked[i][j] !== 1) {
          if (tempClicked[i][j] === 2) {
            tempClicked[i][j] = 0;
          } else {
            tempClicked[i][j] = 2;
          }
        }
      } else {
        tempClicked[i][j] = 1;
      }
      // Loop through to show exposed squares
      // If the square is empty
      if (tempClicked[i][j] === 1 && squares[i][j] === '') {
        //alert('empty');
        let rows = tempClicked.length;
        let cols = tempClicked[0].length;
        let changeMade = true;
        while (changeMade) {
          changeMade = false;
          // Assign adjacent counts
          for (let i=0; i<rows; i++) {
            for (let j=0; j<cols; j++) {
              if (squares[i][j]==='' && tempClicked[i][j]) {
                for (let m=i-1; m<i+2; m++) {
                  for (let n=j-1; n<j+2; n++) {
                    if (m>=0 && m<rows && n>=0 && n<cols) {
                      if (!tempClicked[m][n]) {
                        tempClicked[m][n] = 1;
                        changeMade = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      setClicked(tempClicked);
    }
  }
  
  function startNewGame(c1,r1,m1) {
    // Create the grid array
    let squaresArray = Array(r1);
    let clickedArray = Array(r1);
    for (let i=0; i<r1; i++) {
      squaresArray[i] = Array(c1).fill('');
      clickedArray[i] = Array(c1).fill(0);
    }
    // Assign mines
    let count = m1;
    while (count>0) {
      let k = Math.floor(Math.random() * r1);
      let l = Math.floor(Math.random() * c1);
      if (squaresArray[k][l] !== '*') {
        squaresArray[k][l] = '*';
        count--;
      }
    }
    // Assign adjacent counts
    for (let i=0; i<r1; i++) {
      for (let j=0; j<c1; j++) {
        if (squaresArray[i][j]!=='*') {
          let tot = 0;
          for (let m=i-1; m<i+2; m++) {
            for (let n=j-1; n<j+2; n++) {
              if (m>=0 && m<r1 && n>=0 && n<c1) {
                if (squaresArray[m][n]==='*') {
                  tot++;
                }
              }
            }
          }
          squaresArray[i][j] = (tot) ? tot : '';
        }
      }
    }
    //setStarted(false);
    setSquares(squaresArray);
    setClicked(clickedArray);
    setGameStatus("playing");
  }
  
  const statusString = () => {
    let d = (mineFlags===1) ? mineFlags + ' mine flagged' : mineFlags + ' mines flagged';
    if (gameStatus==='loser') {
      d = "You lost!";
    }
    if (gameStatus==='winner') {
      d = "You won!";
    }
    if (gameStatus==='new') {
      d = "";
    }
    return d;
  }
    
  const statusClass =() => {
    let cn = "game";
    if (gameStatus==='loser') {
      cn = "game loser";
    }
    return cn;
  }
  
  return (
    <Container fluid center aligned style={{ marginTop: '5em', width: '900px'  }}>
      <div className={statusClass()}>
        <Card>
          <Card.Content>
            <div className="game-board">
              <Board
                squares={squares}
                clicked={clicked}
                onClick = {(i,j) => handleClick(i,j) }
                gameStatus = {gameStatus}
              />
            </div>
          </Card.Content>
        </Card>
        <div className="game-info inputField">
          <StartForm
            onClick = {(c,r,m) => startNewGame(c,r,m) }
          />
          <h3>{statusString()}</h3>
        </div>
      </div>
    </Container>
  );
  
}

export default Minesweeper;