import React, { useState } from 'react';
import { PieChart, Pie } from 'recharts';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from "@material-ui/icons/ArrowUpward";
import ExpandMore from "@material-ui/icons/ArrowDownward";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import OfflineBolt from "@material-ui/icons/OfflineBolt";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";

const editFieldStyle =  {
    minWidth: "300px",
}

const NestedListItem = (props) => {
  
    const checkOpen = () => {
        let o = localStorage.getItem('task' + props.item.id + 'open');
        let newo = o === 'true';
        return newo;
    }

    const [isOpen, setIsOpen] = useState(checkOpen);
    const [isCompleted, setIsCompleted] = useState(props.item.completed);
    let textString = props.item.name;

    const localStyle =  {
        paddingLeft: (50*props.nestLevel) + "px",
        paddingTop: "0px",
        paddingBottom: "0px",
    }
    const addStyle =  {
        color: "#CCC",
    }
    const arrowStyle =  {
        padding: "5px",
        color: "#CCC",
    }
    const circleStyle =  {
        padding: "5px",
        color: "#CCC",
    }
    const activeStyle =  {
        color: "rgb(255, 142, 83)",
    }
    const finishedStyle =  {
        color: "rgb(42, 200, 83)",
    }
    const selectedStyle =  {
        paddingLeft: "10px",
        borderRadius: "7px",
        background: "rgb(255, 202, 143)"
    }
    const listStyle =  {
        paddingTop: "0px",
        paddingBottom: "0px",
    }
  
    function _handleTextFieldChange(e) {
        textString = e.target.value
    }
  
    function _handleKeyPress(e,str) {
        if (e.key === 'Enter') {
            if (textString) {
                props.clickAction(str,props.item, textString);
                e.target.parentElement.parentElement.parentElement.classList.remove("Mui-focusVisible");
            }
        }
    }
  
    function toggleOpen(oState = null) {
        if (oState !== null) {
            localStorage.setItem('task' + props.item.id + 'open', oState);
            setIsOpen(oState);
            props.clickAction("setActive",props.item)
        } else {
            localStorage.setItem('task' + props.item.id + 'open', !isOpen);
            setIsOpen(!isOpen);
            props.clickAction("setActive",props.item)
        }
    }
  
    const chartData = [
        { name: 'Completed', fill: "rgb(42, 200, 83)", value: props.item.completedCount },
        { name: 'In process', fill: "rgb(255, 142, 83)", value: props.item.inprogressCount },
        { name: 'Not started', fill: "#eee", value: (props.item.itemCount - props.item.completedCount -props.item.inprogressCount) },
    ];
  
  
    return (
        <div
            className='taskItem'
            key={"div" + props.item.id}
        >
            <ListItem
                //button
                key={"listitem" + props.item.id}
                onMouseDown={() => { toggleOpen() }}
                onTouchEnd={() => { toggleOpen() }}
                style={localStyle}
            >
            {(() => {
                if (props.item.items != null && props.item.items.length > 0) {
                    if (isOpen) {
                        return (
                            <ListItemIcon
                                style={arrowStyle}
                            >
                                <ExpandLess />
                            </ListItemIcon>
                        )
                    } else {
                        if (props.item.task_id===0 || (props.item.completedCount+props.item.inprogressCount===0)) {
                            return (
                                <ListItemIcon
                                    style={arrowStyle}
                                >
                                    <ExpandMore />
                                </ListItemIcon>
                            )
                        } else {
                            if (props.item.completedCount===props.item.items.length) {
                                return (
                                    <ListItemIcon
                                        style={arrowStyle}
                                    >
                                        <CheckCircleOutline
                                            style={finishedStyle}
                                        />
                                    </ListItemIcon>
                                )
                            } else {
                                return (
                                    <ListItemIcon>
                                        <PieChart width={30} height={30}>
                                            <Pie dataKey="value" data={chartData} animationDuration={200} cx={12} cy={10} innerRadius={7} outerRadius={10} startAngle={180} endAngle={-180} fill="rgb(255, 142, 83)" />
                                        </PieChart>
                                    </ListItemIcon>
                                )
                            }
                        }
                    }
                } else {
                    return (
                        <ListItemIcon>
                            <IconButton
                                edge="end"
                                onClick={() => { setIsCompleted(!isCompleted); props.clickAction("status",props.item); }}
                                aria-label="Status-change button"
                                style={circleStyle}
                            >
                                {props.item.status === 0 && (
                                    <RadioButtonUnchecked />
                                )}
                                {props.item.status === 1 && (
                                    <OfflineBolt
                                        style={activeStyle}
                                    />
                                )}
                                {props.item.status === 2 && (
                                    <CheckCircle 
                                        style={finishedStyle}
                                    />
                                )}
                            </IconButton>
                        </ListItemIcon>
                    )
                }
            })()}
            {props.item.edit===0 && (props.item.isActive===1) && (
                <ListItemText
                    primary={props.item.name}
                    style={selectedStyle}
                />
            )}
            {props.item.edit===0 && (props.item.isActive===0) && (
                <ListItemText
                    primary={props.item.name}
                />
            )}
            {props.item.edit===0 && (
                <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="Edit task button"
                        style={addStyle}
                        onClick={() => { props.clickAction("edit",props.item); }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        aria-label="Add new task button"
                        style={addStyle}
                        onClick={() => { toggleOpen(true); props.clickAction("add",props.item); }}
                    >
                        <AddIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
            {props.item.edit===1 && (
                <TextField
                    required
                    id="standard-basic"
                    style={editFieldStyle}
                    defaultValue={props.item.name}
                    onChange={_handleTextFieldChange}
                    onKeyPress={(event) => _handleKeyPress(event,"saveEdit")}
                    autoFocus
                />
            )}
            {props.item.edit===1 && (
                <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="Cancel editing button"
                        style={addStyle}
                        onClick={() => { props.clickAction("cancelEdit",props.item); }}
                    >
                        <CancelIcon />
                    </IconButton>
                    {(props.item.items === null || props.item.items.length === 0) && (
                        <IconButton
                            edge="end"
                            aria-label="Delete task button"
                            style={addStyle}
                            onClick={() => { if (textString) props.clickAction("delete",props.item); }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </ListItemSecondaryAction>
            )}
            {props.item.edit===2 && (
                <TextField
                    required
                    id="standard-basic"
                    style={editFieldStyle}
                    defaultValue=""
                    onChange={_handleTextFieldChange}
                    onKeyPress={(event) => _handleKeyPress(event,"saveAdd")}
                    autoFocus
                />
            )}
            {props.item.edit===2 && (
                <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="Save new task button"
                        style={addStyle}
                        onClick={() => { if (textString) props.clickAction("saveAdd",props.item, textString); }}
                    >
                        <DoneIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        aria-label="Cancel task addition button"
                        style={addStyle}
                        onClick={() => { props.clickAction("cancelAdd",props.item); }}
                    >
                        <CancelIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
            </ListItem>
            {props.item.items !== null && props.item.items.length > 0 && (
                <Collapse
                    key={"collapse"+props.item.id}
                    component="li"
                    in={isOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <List
                        style={listStyle}
                    >
                        {props.item.items.map(
                            filteredTask => {
                                return (
                                    <NestedListItem
                                        key={"nestedlistitem" + filteredTask.id}
                                        tasks={props.tasks}
                                        item={filteredTask}
                                        nestLevel={props.nestLevel+1}
                                        clickAction={ props.clickAction }
                                    />
                                );
                            }
                        )}
                    </List>
                </Collapse>
            )}
        </div>
    );
}

export default NestedListItem;