import React, { useEffect, useState } from 'react';
import { PieChart, Pie } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from "@material-ui/icons/ArrowUpward";
import ExpandMore from "@material-ui/icons/ArrowDownward";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import OfflineBolt from "@material-ui/icons/OfflineBolt";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import TextSnippet from '@mui/icons-material/Note';
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Divider from "@material-ui/core/Divider";
import axios from 'axios';

import { useAuth } from "../../context/auth";
import { handleError } from "../../components/ErrorHandler";
import { Paper } from '@mui/material';
import ContentEditable from 'react-contenteditable'
import Drawer from '@mui/material/Drawer';
import { API_URL } from '../../constants/index.js';
import NestedListItem from "./components/NestedListItem";
import {
  Container,
  Icon
} from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 700,
  },
  addRoot: {
    fontSize: "14px",
  }
}));

const editFieldStyle =  {
    minWidth: "300px",
}

const notesFieldStyle =  {
    border: "1px solid #eeeeee",
    borderRadius: "10px",
    padding: "20px",
    backgroundColor: "#ffffff",
    margin: "20px",
    fontSize: "1rem",
    filter: "drop-shadow(0px 0px 2px #999999)",
}

const NestedList = () => {
  
    const classes = useStyles();
    const listStyle =  {
        paddingTop: "0px",
        paddingBottom: "0px",
    }

    const { authTokens } = useAuth();
    const [isOpen, setIsOpen] = React.useState(false);

    // Inserts the auth token in the header
  
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);

    // Fetches all tasks for a given project
    const fetchTasksByProjectId = async (projectId) => {
      await axios.get(
        `${API_URL}/tasks-by-project-id/${projectId}`,
        {  headers: { Authorization: `${authTokens}`} },
      ).then(function (response) {
        setTasks(response.data);
      }).catch(e => {
        handleError(e);
      });
    };
  
    const [selectedProject, setSelectedProject] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("selectedProjectId");
      const initialValue = JSON.parse(saved);
      if (initialValue) {
        fetchTasksByProjectId(initialValue);
      }
      return initialValue || "";
    });
    //const [selectedProject, setSelectedProject] = useState('');
    const [items, setItems] = useState([]);
    const [adding, setAdding] = useState(false);
    const [notesContent, setNotesContent] = useState('');
    const [originalNotesContent, setOriginalNotesContent] = useState('');
    const [selectedTask, setSelectedTask] = useState();
    
    const toggleDrawer = (newOpen) => () => {
      setIsOpen(newOpen);
    };
    
    const archiveProjectCall = async () => {
      const savedId = localStorage.getItem("selectedProjectId");
      await axios.get(
        `${API_URL}/projects/${savedId}/archive`,
        { headers: { Authorization: `${authTokens}`} },
      ).then(function (response) {
        localStorage.setItem("selectedProjectId", null);
        window.location.reload(true);
      })
      .catch(function (e) {
        handleError(e);
      });
    };
    
    let newProjectName = '';
    
    // Gets the task list from the API
    useEffect(() => {
        const fetchData = async () => {
            await axios(
              `${API_URL}/projects`,
                {  headers: { Authorization: `${authTokens}`} },
                ).then(function (response) {
                    setProjects(response.data);
                }
            );
        };
        fetchData();
    }, [authTokens]);

    useEffect(() => {
        initializeProjectTasks();
    }, [tasks]);
    
    function initializeTask(item) {
        let newItem = item;
        newItem.edit = 0;
        newItem.isActive = 0;
        newItem.items = []
        const subtasks = tasks.filter(task => task.task_id === item.id);
        // If there are subtasks
        if (subtasks.length > 0) {
            let newCount = subtasks.length;
            let newInprogress = 0;
            let newCompleted = 0;
            subtasks.map(filteredTask => {
                let obj = initializeTask(filteredTask);
                if (obj.status===1) {
                    newInprogress++;
                }
                if (obj.status===2) {
                    newCompleted++;
                }
                
                newItem.items.push(obj);
                return null;
            });
            newItem.itemCount = newCount;
            newItem.inprogressCount = newInprogress;
            newItem.completedCount = newCompleted;
            if (newCompleted===newCount) {
                newItem.status=2;
            } else if (newInprogress + newCompleted>0) {
                newItem.status=1;
            } else {
                newItem.status=0;
            }
            
        }
        return newItem;
    }
  
    function initializeProjectTasks() {
        let newItems = [];
        tasks.filter(task => task.task_id === 0).map(filteredTask => {
            let obj = initializeTask(filteredTask);
            newItems.push(obj);
            return null;
        })
        setItems(newItems);
    }
  
    function iterateList(action, item, taskToModify, value = null, newnode=null) {
        if ((action==='setActive') && (item.isActive===1)) {
            item.isActive = 0;
        }
        if (action==='changeNotes' && item.id===taskToModify.id) {
            item.notes = value;
        }
        if ((action==='saveAdd' && item.id===taskToModify.task_id) || (item.id===taskToModify.id)) {
            if (action==="status") {
                item.status = value;
            }
            if (action==="add") {
                const addForm = {
                    id: 0,
                    status: 0,
                    task_id: item.id,
                    project_id: item.project_id,
                    name: "",
                    edit: 2,
                    items: []
                }
                item.items.unshift(addForm);
            }
            if (action==="edit") {
                item.edit = 1;
            }
            if (action==="cancelEdit") {
                item.edit = 0;
            }
            if (action==="saveEdit") {
                item.edit = 0;
                item.name = value;
            }
            if (action==="saveAdd") {
                newnode.items = [];
                newnode.edit = 0;
                newnode.isActive = 0;
                item.items.shift();
                item.items.unshift(newnode);
            }
            if (action==="setActive") {
                item.isActive = 1;
            }
        }
        if (item.items !== null && item.items.length>0) { // If there are subitems
            if ((action==="cancelAdd")&&(taskToModify.task_id===item.id)) { // If deleting, find and delete the child element
                item.items.shift();
            }
            if ((action==="delete")&&(taskToModify.task_id===item.id)) { // If deleting, find and delete the child element
                let arrayNum = -1;
                for (let j=0; j<item.items.length; j++) { // Get the count of the deleted item
                    if (item.items[j].id===taskToModify.id) {
                        arrayNum=j;
                        j=100000;
                    }
                }
                if (arrayNum>=0) {
                    item.items.splice(arrayNum,1);
                }
            }
            let newCount = item.items.length;
            let newInprogress = 0;
            let newCompleted = 0;
            for (let i=0; i<item.items.length; i++) {
                let obj = iterateList(action,item.items[i],taskToModify, value, newnode);
                item.items[i] = obj;
                if (item.items[i].status===1) {
                    newInprogress++;
                }
                if (item.items[i].status===2) {
                    newCompleted++;
                }
            }
            item.itemCount = newCount;
            item.inprogressCount = newInprogress;
            item.completedCount = newCompleted;
        }
        return item;
    }
  
    function iterateProjects(action,taskToModify=null, value=null, newnode=null) {
        let newItems = [...items];
        for (let i=0; i<newItems.length; i++) {
            let obj = iterateList(action,newItems[i],taskToModify, value, newnode);
            newItems[i] = obj;
        }
        setItems(newItems);
    }
  
    const handleClick = (action,taskToModify,value=null) => {
        switch(action) {
            case 'setActive':
                setSelectedTask(taskToModify);
                setOriginalNotesContent(taskToModify.notes);
                setNotesContent(taskToModify.notes);
                iterateProjects(action,taskToModify,value);
                break;
            case 'saveAdd':
                const saveAdd = async () => {
                    await axios.post(
                      `${API_URL}/tasks`,
                      {
                            name: value,
                            task_id: taskToModify.task_id,
                            project_id: taskToModify.project_id
                        },
                        { headers: { Authorization: `${authTokens}`} },
                    ).then(function (response) {
                        iterateProjects(action,taskToModify,value,response.data);
                    })
                    .catch(function (e) {
                        handleError(e);
                    });
                };
                saveAdd();
                break;
            case 'delete':
                const saveDelete = async () => {
                    await axios.delete(
                        `${API_URL}/tasks/${taskToModify.id}`,
                        { headers: { Authorization: `${authTokens}`} },
                    ).then(function (response) {
                        localStorage.removeItem('task' + taskToModify.id + 'open');
                        iterateProjects(action,taskToModify,value);
                    })
                    .catch(function (e) {
                        handleError(e);
                    });
                };
                saveDelete();
                break;
            case 'status':
                let s = taskToModify.status + 1;
                if (s>2) {
                    s = 0;
                }
                const setStatus = async () => {
                    await axios.put(
                        `${API_URL}/task-status/${taskToModify.id}`,
                        { status: s },
                        { headers: { Authorization: `${authTokens}`} },
                    ).then(function (response) {
                        iterateProjects(action,taskToModify,s);
                    })
                    .catch(function (e) {
                        handleError(e);
                    });
                };
                setStatus();
                break;
            case 'saveEdit':
                if (taskToModify.name !== value) {
                    const saveName = async () => {
                        await axios.put(
                            `${API_URL}/tasks/${taskToModify.id}`,
                            {  name: value },
                            {  headers: { Authorization: `${authTokens}`} },
                        ).then(function (response) {
                            iterateProjects(action,taskToModify,value);
                        }).catch(function (e) {
                              handleError(e);
                        });
                      };
                    saveName();
                }
                break;
            default:
                iterateProjects(action,taskToModify,value);
        }
    }
  
    const initializeProject = (e) => {
        setSelectedProject(e.target.value);
        if (e.target.value==="addProject") {
            setAdding(true);
        } else {
            setAdding(false);
            localStorage.setItem("selectedProjectId", e.target.value);
            fetchTasksByProjectId(e.target.value);
        }
    }
    
    function _handleNewProjectKeyPress(e,str) {
        if (e.key === 'Enter') {
            if (newProjectName) {
                addNewProjectCall();
            }
        }
    }
    
    function _handleNewProjectChange(e) {
        newProjectName = e.target.value
    }
    
    const saveNotes = async () => {
        await axios.put(
          `${API_URL}/tasks/${selectedTask.id}`,
            {  notes: notesContent },
            {  headers: { Authorization: `${authTokens}`} },
        ).then(function (response) {
          setOriginalNotesContent(notesContent);
          iterateProjects("changeNotes", selectedTask, notesContent);
        }).catch(function (e) {
            handleError(e);
        });
    }
    
    const _handleNotesChange = (e) => {
        setNotesContent(e.target.value);
    };
    
    const addNewProjectCall = async () => {
        await axios.post(
            `${API_URL}/projects`,
            {
                name: newProjectName,
            },
            { headers: { Authorization: `${authTokens}`} },
        ).then(function (response) {
            window.location.reload(false);
        })
        .catch(function (e) {
            handleError(e);
        });
    };
    
  
    return (
      <Container text style={{ marginTop: '5em' }}>
        <div>
            <div>

                {/* SELECT LIST OF PROJECTS */}
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">Project</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProject}
                        autoWidth
                        label="Select a project"
                        onChange={initializeProject}
                    >
                        {projects.map(obj => {
                            return (
                                <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>
                            );
                        })}
                        <Divider />
                        <MenuItem key="addProject" value="addProject">Add project</MenuItem>
                    </Select>
                </FormControl>

                {/* LINK TO NOTES FORM */}
                <Button
                    onClick={toggleDrawer(true)}
                >
                    <TextSnippet />
                </Button>
              
                {/* ARCHIVE BUTTON */}
                {selectedProject && (
                  <Button
                    onClick={ () => archiveProjectCall() }
                  >
                    <Icon name='archive' />
                  </Button>
                  )}
                    
                {/* THE NESTED LIST DISPLAYED WHEN NOT ADDING A NEW PROJECT */}
                {!adding && (
                    <div>
                        <List
                            style={listStyle}
                            key="projectlist"
                            className={classes.root}
                        >
                            {items.map(filteredTask => {
                                return (
                                    <NestedListItem
                                        key={"nestedlistitem" + filteredTask.id}
                                        tasks={tasks}
                                        item={filteredTask}
                                        nestLevel={0}
                                        clickAction={ (action,taskToModify,value) => handleClick(action,taskToModify,value) }
                                        sx={{
                                            width: 300,
                                            color: 'success.main',
                                            '& .MuiListItemText-root': {
                                              marginTop: '37px',
                                            },
                                          }}
                                    />
                                );
                            })}
                        </List>
                    </div>
                )}
                
                {/* THE FORM DISPLAYED WHEN ADDING A NEW PROJECT */}
                {adding && (
                    <div>
                        <TextField
                            required
                            id="add-project-input"
                            style={editFieldStyle}
                            defaultValue={newProjectName}
                            label="Project Name" 
                            onChange={_handleNewProjectChange}
                            onKeyPress={(event) => _handleNewProjectKeyPress(event,"saveEdit")}
                            autoFocus
                        />
                    </div>
                )}
            </div>
            <div>
                {/* THE NOTES DRAWER */}
                <Drawer
                        anchor="right"
                        open={isOpen}
                        onClose={toggleDrawer(false)}
                        style={{maxWidth: '400px'}}
                      >
                    <ContentEditable
                        style={notesFieldStyle}
                        html={notesContent} // innerHTML of the editable div
                        onChange={_handleNotesChange} // handle innerHTML change
                    />
                        <div style={{textAlign: "center"}}>
                            <Button
                            variant="contained"
                            onClick={saveNotes}
                            disabled={(originalNotesContent == notesContent)}
                        >
                            Save Notes
                        </Button>
                    </div>
                </Drawer>
            </div>
        </div>
      </Container>
    );
}

export default NestedList;