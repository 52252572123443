import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Icon,
} from 'semantic-ui-react'
import PrivateRoute from './PrivateRoute';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import Home from '../pages/Home';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Minesweeper from '../pages/minesweeper/Minesweeper.js';
import TodoList from '../pages/todolist/TodoList';
import SavedLinks from '../pages/SavedLinks';
import Allies from '../pages/Allies';
import { useAuth } from "../context/auth";


const FixedMenuLayout = () => {
  
  function logOutUser() {
    localStorage.removeItem("token");
    window.location.replace('/login');
  }
  
  const { authTokens } = useAuth();
  
  return (
    <BrowserRouter>
      <div>
        {("token" in localStorage) && (
          <Menu fixed='top' inverted>
            <Container>
              <Menu.Item as='a' href="/" header>
                <Image size='mini' src='/monthalution-logo.png' style={{ marginRight: '1.5em' }} />
                Monthalution
              </Menu.Item>
              <Menu.Item as='a' href="/links">
                <Icon name='linkify' />
                Links
              </Menu.Item>
              <Menu.Item as='a' href="/todolist">
                <Icon name='tasks' />
                Tasks
              </Menu.Item>
              <Menu.Item as='a' href="/allies">
                <Icon name='user secret' />
                Allies
              </Menu.Item>
              <Menu.Item as='a' href="/minesweeper">
                <Icon name='bomb' />
                Minesweeper
              </Menu.Item>
              <Dropdown item simple text='User'>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => { logOutUser(); }}
                  >
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Container>
          </Menu>
        )}
        {!("token" in localStorage) && (
          <Menu fixed='top' inverted>
            <Container text>
              <Menu.Item as='a' href="/" header>
                <Image size='mini' src='/monthalution-logo.png' style={{ marginRight: '1.5em' }} />
                Monthalution
              </Menu.Item>
              <Menu.Item as='a' href="/login">
                <Icon name='sign-in' />
                Login
              </Menu.Item>
            </Container>
          </Menu>
        )}
        <Container fluid style={{ marginTop: '7em' }}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <PrivateRoute path="/minesweeper" component={Minesweeper} />
            <PrivateRoute path="/todolist" component={TodoList} />
            <PrivateRoute path="/links" component={SavedLinks} />
            <PrivateRoute path="/allies" component={Allies} />
            <Route path="/" component={Home} />
          </Switch>
        </Container>

        <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
          <Container textAlign='center'>
            <Grid divided inverted stackable>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Group 1' />
                <List link inverted>
                  <List.Item as='a'>Link One</List.Item>
                  <List.Item as='a'>Link Two</List.Item>
                  <List.Item as='a'>Link Three</List.Item>
                  <List.Item as='a'>Link Four</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Group 2' />
                <List link inverted>
                  <List.Item as='a'>Link One</List.Item>
                  <List.Item as='a'>Link Two</List.Item>
                  <List.Item as='a'>Link Three</List.Item>
                  <List.Item as='a'>Link Four</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Group 3' />
                <List link inverted>
                  <List.Item as='a'>Link One</List.Item>
                  <List.Item as='a'>Link Two</List.Item>
                  <List.Item as='a'>Link Three</List.Item>
                  <List.Item as='a'>Link Four</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header inverted as='h4' content='Footer Header' />
                <p>
                  Extra space for a call to action inside the footer that could help re-engage users.
                </p>
              </Grid.Column>
            </Grid>

            <Divider inverted section />
            <Image centered size='mini' src='/monthalution-logo.png' />
            <List horizontal inverted divided link size='small'>
              <List.Item as='a' href='#'>
                Site Map
              </List.Item>
              <List.Item as='a' href='#'>
                Contact Us
              </List.Item>
              <List.Item as='a' href='#'>
                Terms and Conditions
              </List.Item>
              <List.Item as='a' href='#'>
                Privacy Policy
              </List.Item>
            </List>
          </Container>
        </Segment>
      </div>
    </BrowserRouter>
  )
}

export default FixedMenuLayout