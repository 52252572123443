import React from 'react';
import Square from './Square.js';

const Board = (props) => {
  
  function renderGrid() {
    let g = [];
    for (let i = 0; i < props.squares.length; i++) {
      let children = []
      for (let j = 0; j < props.squares[i].length; j++) {
        children.push(<Square
          row={i}
          col={j}
          key={j}
          content={props.squares[i][j]}
          clicked={props.clicked[i][j]}
          onClick={() => props.onClick(i,j)}
          gameStatus={props.gameStatus}
        />)
      }
      g.push(<div className="board-row" key={i}>{children}</div>);
    }
    return g;
  }
  
  return (
    <div>
      {renderGrid()}
    </div>
  );

}

export default Board;