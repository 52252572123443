import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import logoImg from "../img/logo.svg";
//import { Card, Logo, Form, Input, Button, Error } from "../components/AuthForm";
import { Card, Logo, Form, Error } from "../components/AuthForm";
import { Button, Input } from 'reactbulma'
import { useAuth } from "../context/auth";

const Signup = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loadStyle, setLoadStyle] = useState("");
    const { setAuthTokens } = useAuth();
    //const referer = props.location.state.referer || '/';
    const referer = '/';

    function postLogin(e) {
      setLoadStyle("is-loading");
      e.preventDefault();
    
      axios.post(`${process.env.REACT_APP_API_URL}/signup`, {
        email,
        password
      }).then(result => {
        setLoadStyle("");
  	    console.log(result);
        if (result.data.isError) {
            setIsError(true);
        } else {
            setAuthTokens(result.data);
            setLoggedIn(true);
        }
      }).catch(e => {
        setIsError(true);
      });
    }

  return (
    <Card>
      <Logo src={logoImg} />
      <Form onSubmit={postLogin}>
        <div className='inputField'>
          <label htmlFor="username">Email</label>
          <Input
            id="username"
            type="username"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            placeholder="you@monthalution.com"
          />
        </div>
        <div className='inputField'>
          <label htmlFor="password">Password</label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            placeholder="******"
          />
        </div>
        <div className='inputField'>
            <Button variant="contained" primary type="submit" value="Sign In" className={loadStyle} >Sign Up</Button>
        </div>
      </Form>
      <Link to="/login">Already have an account?</Link>
        { isError &&<Error>The username or password provided were incorrect!</Error> }
    </Card>
  );
}

export default Signup;