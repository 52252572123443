import React, { useState } from "react";
import { AuthContext } from "./context/auth";
import NavDrawer from './components/NavDrawer';
import 'semantic-ui-css/semantic.min.css'

function App(props) {
  // Gets the token, if any, from localstorage
  const existingTokens = JSON.parse(localStorage.getItem("token"));
  // Get/setter for authTokens
  const [authTokens, setAuthTokens] = useState(existingTokens);
  
  // Stores the token in localstorage
  const setTokens = (data) => {
	  // Gets the token value
	  const r = JSON.stringify(data.token);
	  // Stores the token in localStorate
	  localStorage.setItem("token", r);
	  // Sets authTokens to the returned data
	  setAuthTokens(data);
  }
  
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <div data-reactroot>
        <NavDrawer authTokens={authTokens} />
      </div>
    </AuthContext.Provider>
  );
}

export default App;