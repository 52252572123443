import React from "react";
import {
  Container,
} from 'semantic-ui-react'

const Home = (props) => {
  return (
    <Container fluid center aligned style={{ marginTop: '5em', width: '900px'  }}>
      <h1 className="ui header">Welcome to Monthalution!</h1>
      <div>So great you could make it!</div>
    </Container>
    
  )
}

export default Home;